/* react toastify css  */
@import url("react-toastify/dist/ReactToastify.css");

/* Custom Animation for Toast */
.toast-animated {
  animation-duration: 0.3s;
  animation-fill-mode: both;
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translate3d(-30%, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.toast-fadeInLeft {
  animation-name: fadeInLeft;
}

@keyframes fadeOutLeft {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(-30%, 0, 0);
  }
}

.toast-fadeOutLeft {
  animation-name: fadeOutLeft;
}

.grigora-custom-scrollbar {
  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #c8c9ca;
    border-radius: 5px;
    border: none;
  }
}

.grigora-custom-scrollbar-dark {
  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background: rgb(63, 63, 63);
  }

  &::-webkit-scrollbar-thumb {
    background-color: #777777;
    border: none;
  }
}

.grigora-hidden-scrollbar {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */

  &::-webkit-scrollbar {
    display: none;
  }
}

.g-toastify {
  background-color: white;
  border-radius: 21px;
  font-size: 14px;
  width: 250px;
  padding-top: 5px;
  padding-bottom: 5px;
  // width: fit-content;
  // min-height: 18px;

  // &.fadeInAnimationReactToastify {
  //   animation: fadeIn ease 0.5s;

  //   @keyframes fadeIn {
  //     0% {
  //       opacity: 0;
  //       transform: translateY(20%);
  //     }

  //     100% {
  //       opacity: 1;
  //       transform: translateY(0%);
  //     }
  //   }
  // }

  // &.fadeOutAnimationReactToastify {
  //   animation: fadeOut ease 0.5s;
  //   visibility: hidden;
  //   @keyframes fadeOut {
  //     0% {
  //       opacity: 1;
  //     }

  //     100% {
  //       opacity: 0;
  //     }
  //   }
  // }

  .Toastify__toast-body {
    // Vertical padding 0
    padding-top: 0;
    padding-bottom: 0;
    min-height: 50px;
  }

  .Toastify__toast-icon {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .Toastify__close-button {
    align-self: normal;
    margin-right: 8px;
  }

  .Toastify__close-button svg {
    width: 15px;
    height: 15px;
  }
}

.wave {
  animation-name: wave-animation; /* Refers to the name of your @keyframes element below */
  animation-duration: 2.5s; /* Change to speed up or slow down */
  transform-origin: 70% 70%; /* Pivot around the bottom-left palm */
  display: inline-block;

  &.infinite {
    animation-iteration-count: infinite;
  }
}

@keyframes wave-animation {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(14deg);
  } /* The following five values can be played with to make the waving more or less extreme */
  20% {
    transform: rotate(-8deg);
  }
  30% {
    transform: rotate(14deg);
  }
  40% {
    transform: rotate(-4deg);
  }
  50% {
    transform: rotate(10deg);
  }
  60% {
    transform: rotate(0deg);
  } /* Reset for the last half to pause */
  100% {
    transform: rotate(0deg);
  }
}

.grigora-action-btn {
  color: white;
  border-radius: 10px;
  padding: 12px 22px;
  cursor: pointer;
  font-size: 16px;
  transition: 0.3s;
  font-weight: 500;

  @apply bg-primary;

  &.small {
    padding: 7px 10px;
  }

  &.disabled {
    opacity: 0.5;
    cursor: default;
  }

  &.hidden {
    opacity: 0;
    pointer-events: none;
  }

  &.inactive {
    cursor: default;
    @apply bg-opacity-50;
  }

  &:not(.inactive):hover {
    @apply bg-primaryDark;
  }

  &.white {
    color: unset;
    @apply bg-ui-white;

    &.inactive {
      cursor: default;
      @apply bg-opacity-50;
    }

    &:not(.inactive):hover {
      @apply bg-ui-whiteDark;
    }
  }

  &.red {
    @apply bg-ui-red;

    &.inactive {
      cursor: default;
      @apply bg-opacity-50;
    }

    &:not(.inactive):hover {
      @apply bg-ui-redDark;
    }
  }

  &.blue {
    @apply bg-ui-og-blue;

    &.inactive {
      cursor: default;
      @apply bg-opacity-50;
    }

    &:not(.inactive):hover {
      @apply bg-ui-og-blue-dark;
    }
  }

  &.transparent {
    color: black;
    @apply bg-transparent;

    &.inactive {
      cursor: default;
      @apply bg-opacity-50;
    }

    &:not(.inactive):hover {
      @apply bg-gray-100;
    }
  }

  &.light{

    @apply bg-white text-primary border border-primary;

    &.inactive {
      cursor: default;
      @apply bg-opacity-50;
    }

    &:not(.inactive):hover {
      @apply bg-gray-100;
    }


  }

}

.ant-switch.ant-switch-checked {
  @apply bg-ui-og-blue border-ui-og-blue;
}
.ant-switch.ant-switch-checked .ant-switch-loading-icon {
  @apply text-ui-og-blue;
}
.ant-switch.ant-switch-checked:not(.ant-switch-disabled) {
  @apply bg-ui-og-blue border-ui-og-blue;
}

.ant-switch.ant-switch-checked:hover:not(.ant-switch-disabled) {
  @apply bg-ui-og-blue-dark border-ui-og-blue-dark;
}

.new-site-btn {
  padding: 8px 24px;
  color: white;
  background: #5cbd20;
  border-radius: 21px;
  transition-duration: 0.3s;
  cursor: pointer;
  &.inactive {
    background: #a4a4a4;
    cursor: default;
  }
}

.grigora-projects__card-text {
  background: #fefeff;
  box-shadow: 0 0 0.25rem rgba(0, 0, 0, 0.075);
  border: 1px solid rgb(243, 243, 243);
  border-radius: 10px;
  position: relative;
  // z-index: 10000;
}
.grigora-projects__card-action {
  width: 100%;
  transition: 0.3s;
  border-width: 1px;
  padding: 4px 12px;
  font-size: 12px;
  border-radius: 4px;
}

.sites-navbar {
  background: #fefeff;
  @apply shadow-main;

  .menu {
    border-radius: 21px;
    overflow: hidden;
  }
}

.delete-site-btn {
  padding: 8px 24px;
  width: 100px;
  color: white;
  background: #bd4c20;
  border-radius: 21px;
  transition-duration: 0.3s;
  cursor: pointer;
  &.cancel {
    background: white;
    color: inherit;
    cursor: pointer;
  }
}

.poper-projects__cards-stats {
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-right: 25px;
  flex: 1;

  .item {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 2px;
    .title {
      font-size: 12px;
      color: #a4a4a4;
    }
    .value {
      font-size: 16px;
      font-weight: 700;
    }
  }
}

.settings-tab {
  input {
    font-size: 16px;
    padding: 8px;
    outline: none;
    border-radius: 5px;
    border: 1px solid #e5e5e5;
  }
}

.schedule-range-picker {
  .ant-picker-ok {
    button {
      @apply bg-primary;
    }
  }
}
